import React, { Component, Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _Get from 'lodash/get';
import _Find from 'lodash/find';
import { startCase, prepareMarketingClaims } from '../../helpers/normalizer';
import ScrollableAnchor, { configureAnchors } from 'react-scrollable-anchor';
import classnames from 'classnames'

import { search, clearSearch } from '../home/actions';
import { fetchSearchResults, setSelected } from './actions';

import { stringNormalizer } from '../../helpers/normalizer';

import './search-result.scss';

import noArtifFlav from '../../assets/img/badges/badge-no-artif-flavors.svg';
import noArtifPreserv from '../../assets/img/badges/badge-no-artif-preservs.svg';
import noSynthColors from '../../assets/img/badges/badge-no-synt-colors.svg';
import noHfcs from '../../assets/img/badges/badge-no-hfcs.svg';
import noMsg from '../../assets/img/badges/badge-no-added-msg.svg';
import fatFree from '../../assets/img/badges/badge-fat-free.svg';
import lite from '../../assets/img/badges/badge-lite.svg';
import noEdta from '../../assets/img/badges/badge-no-edta.svg';

const Search = lazy(() => import('../../components/search/Search'));
const ModalPlusOne = lazy(() => import('../../components/ModalPlusOne'));
const ModalEmailIt = lazy(() => import('../../components/ModalEmailIt'));

class SearchResult extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isClearable: true,
            isNoResults: false,
            displayPlusOneModal: false,
            displayEmailItModal: false,
        };

        this.openPlusOneModal = this.openPlusOneModal.bind(this);
        this.closePlusOneModal = this.closePlusOneModal.bind(this);
        this.toggleEmailItModal = this.toggleEmailItModal.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
    }

    componentDidMount() {
        const { location: { search }, searchIndexResults } = this.props;

        window.scrollTo(0, 0);

        if (search) {
            const searchTerm = stringNormalizer(search.slice(1));
            this.props.fetchSearchResults({ searchTerm });
        }

        if (Object.keys(searchIndexResults).length)
            this.props.clearSearch();
    }

    componentDidUpdate(prevProps) {
        const { isNoResults } = this.state;
        const { loading, location: { search }, searchResults } = this.props;
        const shouldReSearch = search && search !== prevProps.location.search && !loading;
        const thereIsNoResults = !isNoResults && !Object.keys(searchResults).length && !loading;

        if (thereIsNoResults)
            this.setState({ isNoResults: true });

        if (shouldReSearch) {
            const searchTerm = stringNormalizer(search.slice(1));
            this.setState({ isNoResults: false });
            this.props.fetchSearchResults({ searchTerm });
        }
    }

    closePlusOneModal() {
        this.setState({ displayPlusOneModal: false });
    }

    openPlusOneModal() {
        this.setState({ displayPlusOneModal: true });
    }

    toggleEmailItModal() {
        this.setState({
            displayPlusOneModal: !this.state.displayPlusOneModal,
            displayEmailItModal: !this.state.displayEmailItModal
        });
    }

    handleSelection(itemID) {
        this.props.setSelected(itemID);
        this.openPlusOneModal();
    }

    handleSearchChange(e) {
        this.props.search(e.target.value);
    }

    handleEmailItClose = () => {
        this.toggleEmailItModal();
    }

    renderMarketingAllergens(productDetails) {
        if (!productDetails) return;

        const { claims: { marketing } } = productDetails;
        const marketingImgs = {
            fatFree: fatFree,
            lite: lite, msg: noMsg,
            hfcs: noHfcs, edta: noEdta,
            artificialFlavors: noArtifFlav,
            syntheticColors: noSynthColors,
            artificialPreservatives: noArtifPreserv,
        };

        return Object.keys(marketingImgs).map((ent, i) => (
            marketing[ent] === (ent.match(/fatFree|lite/) ? 'Yes' : 'No') ?
                <img src={marketingImgs[ent]} alt={`No${ent}`} key={`marketing-${i}`} /> : null
        ));
    }

    formatProductSize(product) {
        const { caseInfo: { packageType, individualUnitVal, individualUnitPercent: appender } } = product;
        return packageType === 'cup' ?
            `${packageType} (${individualUnitVal} ${appender === 'ONZ' ? 'oz' : appender})` : packageType;
    }

    renderProducts() {
        const { searchResults } = this.props;
        const products = _Get( _Find(searchResults, 'products'), 'products' );
        return products && products.map((item, idx) => {
            return (
                  <Link 
                    to={{ pathname: `/products/${item.slug}`, state: { productCode: item.details.productCode }}} className={classnames({
                        'product-item': true,
                        new: item.details.productCode === 'KE5060B3'
                    })}
                    key={idx}
                   >
                      <div>
                          <div className="product-img-container">
                              <img className="product-img" src={item.details.websiteThumbnail} alt="" />
                              <span>{this.formatProductSize(item.details)}</span>
                          </div>
                          <div className="block-info">
                              <h3>{item.details.name}</h3>
                              <div className="additional-info">
                                  <div>
                                      <span>Product code:</span>
                                      <div><span className="product-code">{item.details.productCode}</span></div>
                                  </div>
                                  <div>
                                      <span>Case GTIN:</span>
                                      <div><span className="product-gtin">{item.details.caseInfo.caseGtin}</span></div>
                                  </div>
                              </div>
                              {prepareMarketingClaims(item.details.claims.marketing)}
                          </div>
                      </div>
                      <div className="allergens">{this.renderMarketingAllergens(item.details)}</div>
                  </Link>
            )
        })
    }

    renderRecipes() {
        const { searchResults } = this.props;
        const recipes = _Get( _Find(searchResults, 'recipes'), 'recipes' );
        return recipes && recipes.map((item, idx) => (
            <Link to={{ pathname: `/recipes/${item.slug}`, state: { recipeID: item.id }}} className="block recipe" key={idx}>
                <div className="block-img"><img src={item.details.thumbnail} alt={`recipe-${idx}`} /></div>
                <div className="block-txt">
                    <div className="block-title"><p>{startCase(item.details.title)}</p></div>
                </div>
            </Link>
        ));
    }

    renderPlusOnes() {
        const { searchResults } = this.props;
        const plusOnes = _Get( _Find(searchResults, 'plusones'), 'plusones' );
        return plusOnes && plusOnes.map((item, idx) => {
            return (
                <div onClick={() => this.handleSelection(item.id)} className="block short" key={`plus-one-${idx}`}>
                    <div className="block-txt">
                        <div className="block-title">{item.details.title}</div>
                    </div>
                </div>
            )
        });
    }

    renderPopularSearches() {
        return (
            <div className="popular-search">
                <ul>
                    <li><Link to="/search-result?asian">asian</Link></li>
                    <li><Link to="/search-result?balsamic">balsamic</Link></li>
                    <li><Link to="/search-result?barbecue">barbecue</Link></li>
                    <li><Link to="/search-result?boom boom">boom boom</Link></li>
                    <li><Link to="/search-result?boom boom sauce">boom boom sauce</Link></li>
                </ul>
                <ul>
                    <li><Link to="/search-result?caesar">caesar</Link></li>
                    <li><Link to="/search-result?french">french</Link></li>
                    <li><Link to="/search-result?honey">honey</Link></li>
                    <li><Link to="/search-result?italian">italian</Link></li>
                    <li><Link to="/search-result?fat free">fat free</Link></li>
                </ul>
                <ul>
                    <li><Link to="/search-result?greek">greek</Link></li>
                    <li><Link to="/search-result?ranch">ranch</Link></li>
                    <li><Link to="/search-result?essentials">essentials</Link></li>
                    <li><Link to="/search-result?ken's">ken's</Link></li>
                    <li><Link to="/search-result?sauce">sauce</Link></li>
                </ul>
            </div>
        );
    }

    render() {
        let results = null;
        const {
            loading, searchResults,
            selected: plusOne, location: { search, hash },
        } = this.props;
        const recipes = _Get( _Find(searchResults, 'recipes'), 'recipes' );
        const products = _Get( _Find(searchResults, 'products'), 'products' );
        const plusOnes = _Get( _Find(searchResults, 'plusones'), 'plusones' );
        const { isNoResults, displayPlusOneModal, displayEmailItModal } = this.state;
        const decodedSearchTerm = search && decodeURIComponent(search.slice(1));

        if (hash) {
            configureAnchors({ offset: -85 });
        }

        if (!isNoResults) {
            results = <div>
                <h2>Search results for "{decodedSearchTerm}"</h2>

                {loading && <div className="loader">Loading...</div>}

                {!loading && products && products.length && (
                    <>
                        <h3>Products</h3>
                        <div className="product-list">
                            {this.renderProducts()}
                        </div>
                    </>
                )}

                {!loading && recipes && recipes.length && (
                    <ScrollableAnchor id={'recipes'}>
                        <div>
                            <h3>Recipes</h3>
                            <div className="recipe-list">
                                {this.renderRecipes()}
                            </div>
                        </div>
                    </ScrollableAnchor>
                )}

                {!loading && plusOnes && plusOnes.length && (
                    <>
                        <h3>Make it your own</h3>
                        <div className="product-list">
                            {this.renderPlusOnes()}
                        </div>
                    </>
                )}

                {
                    // Static
                }
                <h3>Popular searches</h3>
                {this.renderPopularSearches()}
                {
                    // Static
                }
            </div>;
        }

        if (isNoResults) {
            results = <div>
                <h2>Search results for "{decodedSearchTerm}"</h2>
                <div className="descr">Sorry, no results were found. Make sure the spelling is correct or try using a simpler keywords.</div>

                <Suspense fallback={<p className="loading">Loading search...</p>}>
                    <Search
                        className="search-wrapper"
                        onChange={this.handleSearchChange}
                        results={this.props.searchIndexResults}
                        placeholder={'What are you looking for?'}
                    />
                </Suspense>

                {
                    // Static
                }
                <h3>Popular searches</h3>
                {this.renderPopularSearches()}
                {
                    // Static
                }
            </div>;
        }

        return (
            <div>
                <div className="body-wrapper search-result pampas">
                    {results}
                </div>

                {plusOne && (
                    <Suspense fallback={null}>
                        <ModalPlusOne
                            visible={displayPlusOneModal}
                            onEmailItClick={this.toggleEmailItModal}
                            onClose={this.closePlusOneModal}
                        />
                    </Suspense>
                )}

                {plusOne && (
                    <Suspense fallback={null}>
                        <ModalEmailIt
                            visible={displayEmailItModal}
                            type="plus one"
                            from="Plus One Pop Up"
                            pdfFilePath={plusOne.details.pdfFilePath}
                            onClose={this.handleEmailItClose}
                        />
                    </Suspense>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.searchResults.loading,
    selected: state.searchResults.selected,
    searchResults: state.searchResults.list,
    searchIndexResults: state.home.searchResults
});

export default connect(mapStateToProps, {
    search, clearSearch,
    setSelected, fetchSearchResults,
})(SearchResult);
